@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightgray !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageStyle {
      margin-top: 35px !important;
      margin-right: 5% !important;
      margin-left: 5% !important;
  }

  .tealStyle {
    color: #2aa2a7
  }

  .coralStyle {
    color: #f88379
  }
  
  .lightGreyStyle {
    color: grey
  }

  .lightGreyStyleLink {
    color: grey
  }

  .lightGreyStyleLink:hover {
    color: #2aa2a7;
  }
  
  .iconStyle {
    position: "absolute";
    bottom: 0;
    right: 0;
  }

  .titleStyle {
    color: #2aa2a7;
    font-family: 'Playfair Display', serif;

  }

  .subTitleStyle {
    color: #f88379;
    font-family: 'Playfair Display', serif;

  }
.menuStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: white !important;
  }

  a:hover {
    color: #2aa2a7;
  }
